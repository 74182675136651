import {ProductCategory} from '../../contexts/Quote';
import {
  CLASSIQUE_SHAPE,
  DEPORTE_BRAND,
  DEPORTE_SHAPE,
  PRODUCT_CATEGORY,
  PROTECTION_SHAPE,
} from '../Options/data';

export const productCategories: ProductCategory[] = [
  {
    name: PRODUCT_CATEGORY.CLASSIQUE,
    label: 'Embouts classiques',
    img: '/categories/classique.jpg',
    products: [
      {
        name: CLASSIQUE_SHAPE.CANULE,
        label: 'Canule',
        img: '/classique/shape/canule.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.CANULE_EPAULEMENT,
        label: 'Canule épaulement',
        img: '/classique/shape/canule-epaulement.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.CANULE_DEMI_EPAULEMENT,
        label: 'Canule 1/2 épaulement',
        img: '/classique/shape/canuledemiepaulement.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.CANULE_QUART_EPAULEMENT,
        label: 'Canule 1/4 épaulement',
        img: '/classique/shape/canuledemiepaulement.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.SQUELETTE,
        label: 'Squelette',
        img: '/classique/shape/squelette.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.FOND_CONQUE,
        label: 'Fond de conque',
        img: '/classique/shape/fondconque.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.DEMI_CONQUE,
        label: 'Demi-conque',
        img: '/classique/shape/demiconque.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.PINCE_CRABE,
        label: 'Silhouette',
        img: '/classique/shape/pincecrabe.jpg',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.DEPORTE,
    label: 'Embouts pour écouteurs déportés ou micro-tube\n\ncanule coque',
    img: '/categories/deporte.jpg',
    products: [
      {
        name: DEPORTE_SHAPE.DEPORTE,
        label: 'Embouts pour écouteurs déportés ou Micro-tube',
        img: '/deporte/shape/embouts.jpg',
      },
      {
        name: DEPORTE_SHAPE.COQUE,
        label: 'Canule Coque',
        img: '/deporte/shape/coque.jpg',
      },
    ],
    brands: [
      {
        name: DEPORTE_BRAND.SIEMENS,
        label: 'Siemens',
        img: '/deporte/brand/siemens.jpg',
      },
      {
        name: DEPORTE_BRAND.OTICON,
        label: 'Oticon',
        img: '/deporte/brand/oticon.jpg',
      },
      {
        name: DEPORTE_BRAND.BELTONE,
        label: 'Beltone',
        img: '/deporte/brand/beltone.jpg',
      },
      {
        name: DEPORTE_BRAND.WIDEX,
        label: 'Widex',
        img: '/deporte/brand/widex.jpg',
      },
      {
        name: DEPORTE_BRAND.PHONAK,
        label: 'Phonak',
        img: '/deporte/brand/phonak.jpg',
      },
      {
        name: DEPORTE_BRAND.STARKEY,
        label: 'Starkey',
        img: '/deporte/brand/starkey.png',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.PROTECTION,
    label: 'Embouts protecteurs',
    img: '/categories/protection.jpg',
    products: {
      Musique: [
        {
          name: PROTECTION_SHAPE.PIANISSIMO,
          label: 'Pianissimo®',
          img: '/protection/shape/pianissimo.jpg',
          description: (
            <p>
              Protection auditive sur mesure spécialement <b>conçue</b> pour les{' '}
              <b>musiciens</b> qui peut être également utilisée{' '}
              <b>
                dans les bureaux, open spaces plateformes téléphoniques et bien
                d'autres domaines
              </b>
              . Cette protection permet une <b>restitution fidèle du son</b>,
              avec une <b>atténuation linéaire</b> sur toutes les fréquences.{' '}
              <b>4 filtres différents</b> et <b>9 coloris au choix</b>.
              Également disponible en pack avec un kit écouteur...
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.EARBACK_MUSIC,
          label: 'Earback®',
          img: '/protection/shape/earbackMusic.jpg',
          description: (
            <p>
              Une gamme complète de <b>in-ear monitors</b> pour une{' '}
              <b>qualité sonore optimum</b> et une{' '}
              <b>protection auditive efficace</b>. Réalisés <b>sur mesure</b> en
              résine, les EarBack® Music assurent une{' '}
              <b>isolation de l'environnement sonore</b> avec une atténuation
              d'environ <b>-25 dB (A)</b>.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.EAR,
          label: 'Adaptateur Audio',
          img: '/protection/shape/adapteteurAudio_2.jpeg',
          description: (
            <p>
              Embouts personnalisés en <b>silicone</b> dans lesquels sont
              insérés <b>des écouteurs standards</b> ou de forme particulière
              tels que <b>SHURE, écouteurs Bluetooth, Etymotic</b>…<br />
              <br />
              Modèle à préciser lors de la commande.
            </p>
          ),
        },
      ],
      Nomade: [
        {
          name: PROTECTION_SHAPE.GAME_EAR,
          label: 'Game(a)r® Pro',
          img: '/protection/shape/gameear.jpg',
          description: (
            <p>
              Sa fabrication en <b>silicone super souple</b> vous garantira un
              confort incomparable. Muni d'écouteurs Knowles pour une
              restitution <b>haute définition</b> du son, le Game(a)r® Pro est
              la solution idéale pour les joueurs à la recherche d'un confort
              jamais vu auparavant lié à des performances de retour son de{' '}
              <b>qualité professionnelle</b>.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.EAR,
          label: 'Adaptateur Audio',
          img: '/protection/shape/adapteteurAudio_2.jpeg',
          description: (
            <p>
              Sa fabrication en <b>silicone super souple</b> vous garantira un
              confort incomparable. Muni d'écouteurs Knowles pour une
              restitution <b>haute définition</b> du son, le Game(a)r® Pro est
              la solution idéale pour les joueurs à la recherche d'un confort
              jamais vu auparavant lié à des performances de retour son de{' '}
              <b>qualité professionnelle</b>.
            </p>
          ),
        },
      ],
      Bruit: [
        {
          name: PROTECTION_SHAPE.PASSTOP_OS,
          label: 'Le PasStop ® O.S : Silicone',
          img: '/protection/shape/passtop-os.jpg',
          description: (
            <p>
              Protection auditive munie d'un filtre{' '}
              <b>acoustique à atténuation sélective</b>. Conçue sur mesure en
              silicone rosé <b>translucide</b> de forme <b>canule</b> par
              défaut. Également disponible en forme conque en option. Le{' '}
              <b>Passtop O.S</b> est disponible avec un <b>filtre O.S+, O.S1</b>
              , O.S2 ou O.S3 et conforme à la norme EN 352/2.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.PASSTOP_OR,
          label: 'Le PasStop ® O.R : Résine',
          img: '/protection/shape/passtop-or.jpg',
          description: (
            <p>
              Protection auditive munie d'un <b>filtre acoustique</b> à
              atténuation sélective. Conçue <b>sur mesure</b> en résine{' '}
              <b>translucide incolore</b> de forme canule par défaut. Également
              disponible en forme <b>conque en option</b>. Le Passtop O.R est
              disponible avec un filtre O.R1, O.R2 ou O.R3 et conforme à la
              norme EN 352/2.
            </p>
          ),
        },
        // {
        //   name: PROTECTION_SHAPE.PASSTOP_A,
        //   label: 'Le PasStop® A',
        //   img: '/protection/shape/passtop_a.jpg',
        //   description: (
        //     <p>
        //       Protection auditive munie d'un <b>filtre acoustique</b> à
        //       atténuation sélective. Conçue sur mesure <b>en résine noire</b> de
        //       forme <b>canule</b> par défaut et équipée{' '}
        //       <b>d'un cordon amovible</b> et d'une <b>poignée ergonomique</b>{' '}
        //       qui permet une mise en place et un retrait aisé. Le Passtop A est
        //       conforme à la norme EN 352/2
        //     </p>
        //   ),
        // },
        {
          name: PROTECTION_SHAPE.PASSTOP_EP2,
          label: 'Le PasStop® Ep2',
          img: '/protection/shape/passtopEP2.jpg',
          description: (
            <p>
              <b>Le PasStop ® Ep2</b> a l'unique particularité de disposer d'un
              filtre à <b>double atténuation</b> en laiton inoxydable qui est un
              antibactérien naturel. Il offre ainsi la{' '}
              <b>possibilité de choisir, soi-même, l'atténuation</b> que l'on
              désire en fonction de son environnement sonore !
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.OBTURATEUR,
          label: 'Obturateur Anti-Bruit',
          img: '/protection/shape/obturateur.jpeg',
          description: (
            <p>
              Protection auditive sur mesure <b>sans filtre</b> réalisée en{' '}
              <b>silicone</b> de forme conque adaptée à toute{' '}
              <b>application domestique ou professionnelle</b>. Cordon de
              liaison en option. Disponible en <b>10 coloris</b>.
            </p>
          ),
        },
      ],
      Chasse: [
        {
          name: PROTECTION_SHAPE.STOPGUN_E,
          label: 'StopGun® E Classic',
          img: '/protection/shape/stopGunE.jpg',
          description: (
            <p>
              <b>Protection auditive</b> sur <b>mesure électronique</b>{' '}
              numérique permettant <b>l'amplification</b> de l'environnement
              sonore jusqu'à <b>+ 15dB</b> et la stabilisation du niveau sonore
              à partir de <b>80 dB d'entrée</b>. Fabriquée{' '}
              <b>sur mesure en résine</b> de forme conque.
            </p>
          ),
        },
        // {
        //   name: PROTECTION_SHAPE.STOPGUN_FLEX,
        //   label: 'StopGun® Flex',
        //   img: '/protection/shape/stopGunFlex.jpeg',
        //   description: (
        //     <p>
        //       <b>Protection auditive sur mesure électronique numérique</b>{' '}
        //       réalisée en <b>silicone souple</b> équipée d'un système d'
        //       <b>amplification à 3 échelles</b>: + 9, 15 ou 25 dB, permettant
        //       une augmentation de l'environnement sonore et une{' '}
        //       <b>suppression du coup de feu</b>.
        //     </p>
        //   ),
        // },
        {
          name: PROTECTION_SHAPE.PASSTOP_TIR_CHASSE,
          label: 'Passtop® O.S 1 Conque / Tir & Chasse',
          img: '/protection/shape/passtopTirChasse.jpg',
          description: (
            <p>
              Protection <b>auditive munie d'un filtre acoustique</b> à
              atténuation sélective. Conçue sur mesure en <b>silicone rouge</b>{' '}
              de forme conque par défaut. Son filtre breveté permettra à
              l'utilisateur de se protéger efficacement en atténuant de manière{' '}
              <b>significative les bruits impulsionnels</b> (tirs) tout en
              laissant passer la parole.
            </p>
          ),
        },
        // {
        //   name: PROTECTION_SHAPE.PASSTOP_STOPGUN,
        //   label: 'StopGun',
        //   img: '/protection/shape/stopGun.jpeg',
        //   description: (
        //     <p>
        //       Protection auditive <b>sur mesure en silicone équipée</b> d'un
        //       filtre qui permet d'entendre les faibles bruits du <b>gibier</b>{' '}
        //       et <b>écrête</b> les bruits impulsionnels (tirs).
        //     </p>
        //   ),
        // },
      ],
      Sommeil: [
        {
          name: PROTECTION_SHAPE.SWEETNIGHT,
          label: 'Sweetnight® Conque',
          img: '/protection/shape/sweetnightConque.jpeg',
          description: (
            <p>
              Protection <b>auditive sur mesure</b> de forme conque réalisée en{' '}
              <b>silicone extra-souple</b> pour <b>éviter</b> toute sensation{' '}
              <b>de gêne pendant le sommeil</b>. Atténuation estimée de -15 à
              -20 dB variable en fonction du conduit auditif. Idéal pour les
              personnes qui <b>souhaitent se préserver</b> des bruits{' '}
              <b>de voisinage nocturne</b>.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.SWEETNIGHT_CANULE,
          label: 'Sweetnight® Canule',
          img: '/protection/shape/sweetnightCanule.jpeg',
          description: (
            <p>
              Canule avec <b>poignée ergonomique</b> et <b>conduit</b> évidé
              réalisée en <b>silicone extra-souple</b>. Allie <b>confort</b> et{' '}
              <b>bonne tenue</b> dans l'oreille. Atténuation estimée de -20 à
              -25 dB variable en fonction du conduit auditif. Idéal pour les{' '}
              <b>
                personnes qui souhaitent se préserver des bruits de voisinage
                nocturne
              </b>
              .
            </p>
          ),
        },
      ],
      Eau: [
        {
          name: PROTECTION_SHAPE.AQUASTOP,
          label: 'AquaStop® Flottant',
          img: '/protection/shape/aquastop_flottant.jpeg',
          description: (
            <p>
              Recommandé pour toutes les{' '}
              <b>
                pathologies interdisant le contact de l'eau dans le conduit
                auditif
              </b>
              .<br />
              La poignée d'extraction associée à sa <b>matière souple</b>{' '}
              rendent sa manipulation très facile même pour les enfants !<br />
              Grâce à ses <b>propriétés flottantes</b>, vous pourrez facilement
              retrouver vos embouts remontant à la surface de l'eau.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.OBTURATEUR,
          label: 'Obturateur Anti-Eau',
          img: '/protection/shape/obturateur.jpeg',
          description: (
            <p>
              Protection auditive{' '}
              <b>sur mesure sans filtre réalisée en silicone</b>. Imperméabilité
              totale grâce à sa <b>forme conque</b> idéale pour les{' '}
              <b>
                pathologies interdisant le contact de l'eau avec le conduit
                auditif
              </b>
              . Cordon de liaison en option. Disponible en 10 coloris.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.AQUASON,
          label: 'AquaSon®',
          img: '/protection/shape/aquason.jpeg',
          description: (
            <p>
              Étanche à l'<b>eau</b>, au <b>vent</b> et au <b>sable</b>,
              l'AquaSon®, grâce à une atténuation auditive minimale, a
              l'avantage de <b>protéger vos oreilles de tout corps étranger</b>{' '}
              tout en permettant la <b>captation des sons et de la parole</b>.
              <br />
              Il est réalisé en <b>silicone bleu translucide</b>, équipé d'un{' '}
              <b>filtre anti-intrusion</b> garantissant sa parfaite étanchéité.
              Il est également muni d'un cordon PVC anti-nœuds.
            </p>
          ),
        },
      ],
      Communication: [
        {
          name: PROTECTION_SHAPE.OREILLETTE,
          label: 'Oreillette personnalisée spécial pilote',
          img: '/protection/shape/oreillettePerso.jpg',
          description: (
            <p>
              Protection <b>sur mesure réalisée en silicone</b> de forme conque
              pour la connexion sur appareils de{' '}
              <b>radio-communication ou téléphonie</b>. Permet la réception du
              message audio dans des <b>environnements très bruyants</b> et
              protège l'audition. Préciser la longueur du câble souhaité ainsi
              que la connectique (Jack, broches…).
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.EAR,
          label: 'Adaptateur Audio',
          img: '/protection/shape/adapteteurAudio_2.jpeg',
          description: (
            <p>
              Embouts personnalisés en <b>silicone</b> dans lesquels sont
              insérés <b>des écouteurs standards</b> ou de forme particulière
              tels que <b>SHURE, écouteurs Bluetooth, Etymotic</b>…<br />
              Modèle à préciser lors de la commande.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.PASSTOP_OS_CONQUE,
          label: 'Passtop O.S conque pour tube acoustique',
          img: '/protection/shape/passtop-os-conque.jpg',
          description: (
            <p>
              <b>Version communication</b> du Passtop O.S, cette protection{' '}
              <b>auditive</b> est munie d'un <b>filtre acoustique</b> à
              atténuation sélective. Conçue sur mesure en{' '}
              <b>silicone rosé translucide</b> de forme conque par défaut. Livré
              avec un <b>tube acoustique</b> pour la transmission du son dans
              l'oreille, à l'abri <b>des bruits perturbateurs</b>.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.OBTURATEUR_COM,
          label: 'Obturateur communication',
          img: '/protection/shape/obturateurCommunication.jpg',
          description: (
            <p>
              Protecteur antibruit sans <b>filtre</b> réalisé en silicone de
              forme <b>conque</b>. Muni d'un tube <b>acoustique spiralé</b> pour
              connexion sur appareils de <b>radiocommunication</b>. Permet
              d'allier protection auditive et utilisation de{' '}
              <b>radio simultanément</b>.
            </p>
          ),
        },
        {
          name: PROTECTION_SHAPE.SECRETEAR,
          label: 'Secrethear',
          img: '/protection/shape/secretHear.jpeg',
          description: (
            <p>
              Oreillette <b>sur mesure</b> réalisée en <b>résine translucide</b>{' '}
              de forme <b>squelette particulièrement</b> discrète munie d'un
              tube spiralé pour adaptation sur écouteur externe. Le conduit
              auditif reste largement{' '}
              <b>
                ouvert pour permettre également une excellente communication de
                proximité
              </b>
              .
            </p>
          ),
        },
      ],
    },
  },
];
